/* QTVagaRound Regular */
@font-face {
  font-family: "QTVagaRound";
  src: url("./fonts/QTVagaRound.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

/* QTVagaRound Bold */
@font-face {
  font-family: "QTVagaRound";
  src: url("./fonts/QTVagaRound-Bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}
